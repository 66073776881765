<template>
  <div>
    <!-- Filter -->
    <div class="d-flex justify-content-end mt-n3">
      <div class="col-md-3">
        <b-input-group>
          <template #prepend>
            <b-input-group-text squared>
              <b-icon-search></b-icon-search>
            </b-input-group-text>
          </template>
          <b-form-input
            type="text"
            v-model="filter.name"
            placeholder="Cari"
            @keyup="filterByName"></b-form-input>
        </b-input-group>
      </div>
    </div>
    <!-- Table -->
    <div class="overflow-auto table-setting">
      <b-table
        striped
        hover
        :items="items"
        :fields="fields"
        :style="'white-space: nowrap'"
        responsive="sm">
        <template #table-colgroup="scope">
          <col
            v-for="field in scope.fields"
            :key="field.key"
            :style="{
              width:
                field.key === 'actions'
                  ? purpose == 'master'
                    ? '20%'
                    : ''
                  : '',
            }" />
        </template>
        <template #cell(description)="data">
          <span class="d-inline-block text-truncate" style="max-width: 150px;">{{ data.item.description }}</span>
        </template>
        <template #cell(status)="data">
          <!-- <b-form-checkbox id="input-status" v-model="data.item.status" switch>
              <b>({{ data.item.status ? "Aktif" : "Non-Aktif" }})</b>
            </b-form-checkbox> -->
          {{ data.item.status ? "Aktif" : "Non-Aktif" }}
        </template>
        <template #cell(actions)="data">
          <template v-if="purpose == 'master'">
            <b-button
              size="sm"
              v-b-tooltip.hover
              v-if="data.item.status == 1"
              title="Non Aktifkan"
              @click="
                setDefault(data.item.id, data.item.order, data.item.status)
              "
              class="mr-1 btn-success"><i class="fas fa-check px-0"></i></b-button>
            <b-button
              size="sm"
              v-b-tooltip.hover
              v-if="data.item.status == 0"
              title="Aktifkan"
              class="mr-1 btn-primary"
              @click="
                setDefault(data.item.id, data.item.order, data.item.status)
              "><i class="fas fa-check px-0"></i></b-button>
            <b-button
              size="sm"
              v-b-tooltip.hover
              title="Detail"
              class="mr-1 btn-info"
              @click="btnDetailOnClick(data.item.id)"><i class="fas fa-eye px-0"></i></b-button>
            <b-button
              size="sm"
              v-b-tooltip.hover
              title="Edit"
              class="mr-1 btn-success"
              @click="btnEditOnClick(data.item.id)"><i class="fas fa-edit px-0"></i></b-button>
            <b-button
              size="sm"
              v-b-tooltip.hover
              title="Hapus"
              class="btn-danger"
              @click="deleteData(data.item.id)"><i class="fas fa-trash px-0"></i></b-button>
          </template>
        </template>
      </b-table>
      <b-pagination
        v-if="items.length != 0"
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        @page-click="paginationClick"
        class="mt-4"></b-pagination>
    </div>
  </div>
</template>

<style scoped>
.image-input-wrapper {
  width: 180px !important;
  height: 62px !important;
}

.image-input-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.list-setting-table {
  vertical-align: middle !important;
}
</style>

<script>

import { debouncer } from "@/core/modules/Helper.js"
import CrudModule from "@/core/modules/CrudModule.js";
import api from "@/core/services/api.service.js";

export default {
  props: {
    purpose: String,
  },

  data() {
    return {
      filter: {
        name: "",
      },
      perPage: 20,
      currentPage: 1,
      totalRows: 0,
      fields: [
        {
          key: "number",
          label: "#",
          sortable: true,
        },
        {
          key: "title",
          label: "Judul Layanan",
          sortable: true,
        },
        {
          key: "status",
          label: "Status",
          sortable: true,
        },
        {
          key: "order",
          label: "Urutan",
          sortable: true,
        },
        {
          key: "description",
          label: "Deskripsi Layanan",
          sortable: true,
        },
        { key: "actions", label: "Aksi" },
      ],
      items: [],
    };
  },

  methods: {
    async paginationClick(evt, page) {
      evt.preventDefault();
      this.currentPage = page;
      await this.showdata();
    },

    async showdata() {
      let filterParams = `&name=${this.filter.name}`;
      let response = await CrudModule.paginate(
        "website-services",
        `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`
      );
      let pagination = response.meta.pagination;
      this.totalRows = pagination.total;

      let c, b;
      b = this.perPage * (this.currentPage - 1) + 1;
      for (c = 0; c < response.data.length; c++) {
        response.data[c].number = b + c;
      }

      let a;
      for (a = 0; a < response.data.length; a++) {
        if (response.data[a].status == 0) {
          response.data[a].status = false;
        } else if (response.data[a].status == 1) {
          response.data[a].status = true;
        }
      }

      this.items = response.data;
    },

    filterByName() {
      debouncer(() => { this.showdata() }, 500)
    },

    async deleteData(id) {
      let result = await CrudModule.delete("website-services/" + id);
      if (result) {
        this.showdata();
      }
    },

    async setDefault(id, order, status) {

      api.setHeader();
      // conv_status = null
      if (status == true) {
        status = 1;
      } else if (status == false) {
        status = 0;
      }

      let result = await api.put(
        "website-services/" + id + "/" + order + "/" + status + "/set-active"
      );

      if (result.data.item.original == null) {
        Swal.fire(result.data.title, result.data.message, "error");

      } else {

        this.showdata();
      }
    },

    async btnEditOnClick(id) {
      let webService = await CrudModule.get("website-services/" + id);
      // If Data Not Found
      if (webService == null) {
        // Redirect To List
        // this.$router.push('/donors')
      } else {
        this.$root.$emit("getWebsiteServiceById", webService);
      }
    },

    async btnDetailOnClick(id) {
      let webService = await CrudModule.get("website-services/" + id);
      // If Data Not Found
      if (webService == null) {
        // Redirect To List
        // this.$router.push('/donors')
      } else {
        this.$root.$emit("getWebsiteServiceByIdDetail", webService);
      }
    },
  },

  mounted() {
    this.showdata();

    this.$root.$on("updateWebsiteService", () => {
      this.showdata();
    });
  },
};
</script>

<style></style>
